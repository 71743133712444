import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Paper,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { GoogleMap, Marker, useLoadScript, Autocomplete } from "@react-google-maps/api";
import TopBar from "../../components/TopBar";
import Footer from "../../components/Footer";
import "./PreCheckScreen.css";

const PreCheckPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { cartItems, totalPrice } = location.state;

  const [deliveryOption, setDeliveryOption] = useState("home");
  const [deliveryNote, setDeliveryNote] = useState("");
  const [coordinates, setCoordinates] = useState({ lat: 0.3476, lng: 32.5825 });
  const [address, setAddress] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [autocomplete, setAutocomplete] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDwOwz4ybDE8SjOUP3dydRFgsigu2nOAmw",
    libraries: ["places"],
  });

  useEffect(() => {
    // Fetch any required data here
  }, []);

  const handleDeliveryOptionChange = (event) => setDeliveryOption(event.target.value);

  const handleDeliveryNoteChange = (event) => setDeliveryNote(event.target.value);

  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setCoordinates({ lat, lng });
    setLatitude(lat);
    setLongitude(lng);
    setAddress(""); // Reset address when map is clicked
  };

  const handleAddButtonClick = () => {
    if (!address || !longitude || !latitude) {
      alert("Please search and select an address first!");
      return;
    }
    setIsModalOpen(true);
  };

  const handlePlaceSelected = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        setCoordinates({ lat, lng });
        setAddress(place.formatted_address);
        setLongitude(lng);
        setLatitude(lat);
      }
    }
  };

  const handleCloseModal = () => setIsModalOpen(false);

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <>
      <TopBar />
      <div className="precheck-container">
        <div className="precheck-map-section">
          <GoogleMap
            mapContainerClassName="precheck-map-container"
            center={coordinates}
            zoom={13}
            onClick={handleMapClick}
          >
            <Marker position={coordinates} />
          </GoogleMap>

          <div>
            <p>Deliver to:</p>
            <Autocomplete
              onLoad={(autocomplete) => setAutocomplete(autocomplete)}
              onPlaceChanged={handlePlaceSelected}
            >
              <TextField
                label="Search Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                fullWidth
              />
            </Autocomplete>

            <Button variant="outlined" onClick={handleAddButtonClick}>
              Add
            </Button>
          </div>
        </div>

        <div className="precheck-form-section">
          <Paper className="precheck-paper">
            <Typography variant="h6" className="precheck-typography-heading">
              Checkout
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup value={deliveryOption} onChange={handleDeliveryOptionChange}>
                <FormControlLabel value="home" control={<Radio />} label="Home Delivery" />
                <FormControlLabel value="pickup" control={<Radio />} label="Self Pickup" />
              </RadioGroup>
            </FormControl>

            <TextField
              label="Delivery Note"
              value={deliveryNote}
              onChange={handleDeliveryNoteChange}
              fullWidth
              multiline
              rows={4}
              variant="outlined"
            />

            <FormControlLabel
              control={<Checkbox color="primary" />}
              label="I confirm that my details are correct"
            />

            <div className="precheck-total-amount">
              <Typography variant="body1">
                <strong>Total for Products:</strong> {totalPrice} UGX
              </Typography>
              <Typography variant="h6" style={{ marginTop: "10px" }}>
                <strong>Total Amount:</strong> {totalPrice} UGX
              </Typography>
            </div>

            <Button variant="contained" color="primary" fullWidth>
              Proceed to Payment
            </Button>
          </Paper>
        </div>
      </div>

      {/* Modal for Address Details */}
      <Dialog open={isModalOpen} onClose={handleCloseModal} maxWidth="md" fullWidth>
  <DialogTitle>Address Details</DialogTitle>
  <DialogContent>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          label="Address Name"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField label="Address Type" fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Longitude"
          value={longitude}
          onChange={(e) => setLongitude(e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Latitude"
          value={latitude}
          onChange={(e) => setLatitude(e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField label="House Number" fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextField label="Floor Number" fullWidth />
      </Grid>
    </Grid>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseModal} color="primary">
      Close
    </Button>
    <Button 
      onClick={() => {
        // Add your save address logic here
        console.log("Address saved:", { address, longitude, latitude });
        handleCloseModal();
      }} 
      color="primary"
      variant="contained"
    >
      Save Address
    </Button>
  </DialogActions>
</Dialog>


      <Footer />
    </>
  );
};

export default PreCheckPage;
