import React, { useEffect, useState, Suspense, lazy } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import TopBar from "../../components/TopBar";
import Skeleton from "@mui/material/Skeleton";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import "./ProductDetails.css";
import FullWidthTabs from "./FullWidthTabs";
import paymentImg from "../../assets/img/payment.webp";
import Footer from "../../components/Footer";
import books from "../../assets/img/books.png";
import WishlistPopover from "../../components/WishlistPopover";

// Lazy-loaded components
const ProductImage = lazy(() => import("../../components/ProductImage"));

const ProductDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const [showFullDescription, setShowFullDescription] = useState(false);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await fetch(
          `https://admin.aristoc.co.ug:9443/api/v1/products/details/${id}`
        );
        if (!response.ok) throw new Error("Network response was not ok");
        const data = await response.json();
        setProduct(data.product);
      } catch (error) {
        console.error("Error fetching product details:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await fetch(
          "https://admin.aristoc.co.ug:9443/api/v1/categories/products/4"
        );
        if (!response.ok) throw new Error("Network response was not ok");
        const data = await response.json();
        setCategories(data || []);
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setCategoriesLoading(false);
      }
    };

    fetchProductDetails();
    fetchCategories();
  }, [id]);

  useEffect(() => {
    if (product) {
      const fetchSimilarProducts = async () => {
        const categoryIds = product.category_ids.map((cat) => cat.id);
        try {
          const response = await fetch(
            `https://admin.aristoc.co.ug:9443/api/v1/categories/products/${categoryIds[1]}`
          );
          if (!response.ok) throw new Error("Network response was not ok");
          const data = await response.json();
          setSimilarProducts(data.filter((p) => p.id !== product.id)); // Exclude the current product
        } catch (error) {
          console.error("Error fetching similar products:", error);
        }
      };
      fetchSimilarProducts();
    }
  }, [product]);

  const addToCart = () => {
    if (product) {
      const cartItem = {
        id: product.id,
        name: product.name,
        quantity,
        price: product.price,
        image: product.image,
      };

      console.log(cartItem);

      let cart = JSON.parse(localStorage.getItem("cart")) || [];
      const existingItemIndex = cart.findIndex(
        (item) => item.id === cartItem.id
      );

      if (existingItemIndex !== -1) {
        cart[existingItemIndex].quantity += cartItem.quantity;
      } else {
        cart.push(cartItem);
      }

      localStorage.setItem("cart", JSON.stringify(cart));
      window.dispatchEvent(new Event("cartUpdated"));
    }
  };

  const handleQuantityChange = (delta) => {
    setQuantity((prevQuantity) => Math.max(prevQuantity + delta, 1));
  };

  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const truncateDescription = (text, wordLimit) => {
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : text;
  };

  const getCategoryName = (categoryId) => {
    const category = categories.find(
      (cat) => Number(cat.id) === Number(categoryId)
    );
    return category ? category.name : "";
  };
  console.log(getCategoryName(1));

  return (
    <div>
      <TopBar />
      <div className="product-details-container">
        <div className="product-main-content">
          <div className="product-top-row">
            <div className="product-image-container">
              <Suspense
                fallback={
                  <Skeleton variant="rectangular" width={400} height={400} />
                }
              >
                {product && (
                  <ProductImage
                    product={product}
                    className="productDetailsImage"
                  />
                )}
              </Suspense>
            </div>
            <div className="product-info-container">
              {loading ? (
                <Skeleton variant="text" width={300} height={40} />
              ) : product ? (
                <>
                  <h1 className="product-detail-title">{product.name}</h1>
                  <Chip
                    label="Bestseller"
                    sx={{
                      backgroundColor: "#C45500",
                      color: "white",
                      borderRadius: 0,
                    }}
                  />
                  <p
                    className="category-name"
                    style={{ color: "#007185", fontWeight: 500 }}
                  >
                    in {getCategoryName(product.category_ids[0]?.id)}
                  </p>
                  <Divider style={{ marginBottom: "20px" }} />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: showFullDescription
                        ? product.description
                        : truncateDescription(product.description, 70),
                    }}
                  />
                  {product.description.split(" ").length > 70 && (
                    <button
                      className="read-more-link"
                      onClick={handleToggleDescription}
                    >
                      {showFullDescription ? "Read Less" : "Read More"}
                    </button>
                  )}
                  <p className="product-id">ISBN: {product.tax}</p>
                  <p className="product-details-price">
                    UGX {product.price.toLocaleString()}
                  </p>
                  <div className="buy-container">
                    <div className="quantity-controls">
                      <button
                        className="quantity-button"
                        onClick={() => handleQuantityChange(-1)}
                      >
                        <RemoveIcon />
                      </button>
                      <input
                        type="text"
                        className="quantity-input"
                        value={quantity}
                        readOnly
                      />
                      <button
                        className="quantity-button"
                        onClick={() => handleQuantityChange(1)}
                      >
                        <AddIcon />
                      </button>
                    </div>
                    <WishlistPopover productId={product.id} />
                    <button className="buy-button" onClick={addToCart}>
                      Add to Cart
                    </button>
                  </div>
                </>
              ) : (
                <div>Product not found.</div>
              )}
            </div>
          </div>

          <div className="tabs-container">
            <FullWidthTabs choiceOptions={product?.choice_options || []} />
          </div>
        </div>

        <div className="product-categories-container">
          <h2
            style={{
              fontFamily: "sans-serif",
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "16px",
              textAlign: "center",
            }}
          >
            Similar Products
          </h2>
          {similarProducts.length === 0 ? (
            <Skeleton variant="rectangular" width="100%" height={100} />
          ) : (
            <div className="similar-products">
              {similarProducts.slice(0, 5).map((similarProduct) => (
                <a
                  key={similarProduct.id}
                  href={`/product-details/${similarProduct.id}`}
                  style={{ cursor: "pointer" }}
                >
                  <div className="similar-product-card">
                    <div className="similar-product-image">
                      {similarProduct && (
                        <ProductImage
                          product={similarProduct}
                          className="similar-product-image"
                          alt={similarProduct.name}
                        />
                      )}
                    </div>
                    <div className="similar-product-details">
                      <p className="similar-product-name">
                        {similarProduct.name.length > 45
                          ? `${similarProduct.name.slice(0, 45)}...`
                          : similarProduct.name}
                      </p>
                      <p className="similar-product-price">
                        UGX{similarProduct.price}
                      </p>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetails;
