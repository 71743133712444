import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './ProductImage.css';

const ProductImage = ({ product }) => {
  const [imageSrc, setImageSrc] = useState(`https://admin.aristoc.co.ug:9443/product/${product.image[0]}`);

  const handleError = () => {
    // On error, try loading from the storage folder
    setImageSrc(`https://admin.aristoc.co.ug:9443/storage/product/${product.image[0]}`);
  };

  return (
    <img
      src={imageSrc}
      alt={product.name}
      onError={handleError} // If the image is not found in public, try the storage folder
      className="product-image"
    />
  );
};

ProductImage.propTypes = {
  product: PropTypes.shape({
    image: PropTypes.arrayOf(PropTypes.string).isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProductImage;
