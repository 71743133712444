import React, { useRef } from 'react';
import './CategorySlider.css'; 
import banner1 from '../../assets/img/Black and Beige Minimalist Black Friday Sale Banner.png';
import banner2 from '../../../src/assets/img/Black and White Modern Fashion Gift Voucher Ticket.jpg';

const Banner = ({ image, title, description, buttonLabel }) => (
  <div className="banner-ad-component">
    <div className="banner-content">
      {/* Left Column: Title, Description, Button */}
      <div className="banner-text">
        <h2>{title}</h2>
        <p>{description}</p>
        <button className="banner-button">{buttonLabel}</button>
      </div>
      {/* Right Column: Image */}
      <div className="banner-image">
        <img src={image} alt={title} />
      </div>
    </div>
  </div>
);


const CategorySlider = () => {
  const sliderRef = useRef(null);

  const scrollLeft = () => {
    if (sliderRef.current) {
      const widthToScroll = sliderRef.current.clientWidth; // Scroll by container width or single item width
      sliderRef.current.scrollBy({ left: -widthToScroll, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      const widthToScroll = sliderRef.current.clientWidth; // Scroll by container width or single item width
      sliderRef.current.scrollBy({ left: widthToScroll, behavior: 'smooth' });
    }
  };

  return (
    <div className="category-slider-container">
      <button className="scroll-button left" onClick={scrollLeft}>&#8249;</button>
      <div className="category-slider" ref={sliderRef}>
        <Banner
          backgroundColor="#ff9999"
          image={banner1}
          title="Books"
          description="Explore our wide range of books."
          buttonLabel="Shop Now"
        />
        <Banner
          backgroundColor="#99ccff"
          image={banner2}
          title="Gift Cards"
          description="Get the best offer for Gift Cards."
          buttonLabel="Shop Now"
        />
      </div>
      <button className="scroll-button right" onClick={scrollRight}>&#8250;</button>
    </div>
  );
};

export default CategorySlider;
