import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import "./Checkout.css";
import TopBar from "../../components/TopBar";
import Modal from "../../components/Modal";
import { FlutterWaveButton, closePaymentModal } from "flutterwave-react-v3";
import ProductImage from "../../components/ProductImage";

const Checkout = ({ clearCart }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { cartItems, totalPrice } = location.state || {
    cartItems: [],
    totalPrice: 0,
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [modalUrl, setModalUrl] = useState(null);
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
  const [notification, setNotification] = useState("");
  const [coordinates, setCoordinates] = useState({
    lat: 0.3476,
    lng: 32.5825, // Default coordinates for Kampala, Uganda
  });

  // Fetch account details on mount
  useEffect(() => {
    const fetchAccountDetails = async () => {
      const token = localStorage.getItem("authToken");
      if (!token) {
        setNotification("No authentication token found");
        return;
      }

      try {
        const response = await fetch(
          "https://admin.aristoc.co.ug:9443/api/v1/customer/info",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) throw new Error("Failed to fetch data");

        const data = await response.json();
        setFirstName(data.f_name || "");
        setLastName(data.l_name || "");
        setEmail(data.email || "");
        setPhone(data.phone || "");
        setAddress(data.address || "");
      } catch (error) {
        setNotification("Failed to fetch account details.");
      }
    };

    fetchAccountDetails();
  }, []);

  useEffect(() => {
    if (isPaymentSuccessful) handlePlaceOrder();
  }, [isPaymentSuccessful]);

  const handlePlaceOrder = () => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      alert("You are not logged in.");
      return;
    }

    const cartPayload = cartItems.map((item) => ({
      product_id: item.id.toString(),
      price: Math.floor(item.price).toString(),
      variant: item.variant || "",
      variation: item.variation || [],
      discount_amount: item.discountAmount || 0,
      quantity: item.quantity,
      tax_amount: item.taxAmount || 0,
    }));

    const orderData = {
      order_amount: totalPrice,
      delivery_address_id: 3,
      order_type: "delivery",
      branch_id: 1,
      cart: cartPayload,
      coupon_discount_amount: 0,
      coupon_discount_title: "",
      payment_method: "flutterwave",
      order_note: "",
      distance: 2.3425712809441035,
      coordinates: coordinates, // Including selected coordinates for delivery
    };

    fetch("https://admin.aristoc.co.ug:9443/api/v1/customer/order/place", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
        Accept: "application/json",
      },
      body: JSON.stringify(orderData),
    })
      .then((response) => {
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        return response.json();
      })
      .then((data) => {
        if (data && data.success) {
          setModalUrl(data.redirectUrl || "");
          alert("Order placed successfully!");
          clearCart();
          navigate("/shop");
        } else {
          clearCart();
          navigate("/shop");
          alert("Order placed successfully!");
        }
      })
      .catch((error) => {
        alert("An error occurred during the transaction. Please try again.");
      });
  };

  const handleFlutterwaveCallback = (response) => {
    if (response.status === "successful") {
      setIsPaymentSuccessful(true);
      closePaymentModal();
    } else {
      alert("Payment was not successful.");
      closePaymentModal();
    }
  };

  const flutterwaveConfig = {
    public_key: "FLWPUBK_TEST-b3cac6aa2beb4927aa482c250f1404bc-X",
    tx_ref: Date.now().toString(),
    amount: 50,
    currency: "UGX",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email,
      phone_number: phone,
      name: `${firstName} ${lastName}`,
    },
    customizations: {
      title: "Aristoc",
      description: "Payment for items in cart",
      logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
    },
    callback: handleFlutterwaveCallback,
    onClose: () => {},
  };

  const handleMapClick = (event) => {
    setCoordinates({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };

  return (
    <>
      <TopBar />
      <div className="checkout-container">
        {notification && <p className="notification">{notification}</p>}

        <div className="billing-details">
          <h2>Billing Details</h2>
          <form>
            <div className="form-group">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                id="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone</label>
              <input
                type="tel"
                id="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </form>
        </div>

        <div className="map-container">
          <h3>Select Delivery Location on Google Maps</h3>
          <LoadScript googleMapsApiKey="AIzaSyDwOwz4ybDE8SjOUP3dydRFgsigu2nOAmw">
            <GoogleMap
              mapContainerStyle={{ height: "300px", width: "100%" }}
              center={coordinates}
              zoom={13}
              onClick={handleMapClick}
            >
              <Marker position={coordinates} />
            </GoogleMap>
          </LoadScript>
        </div>

        <div className="order-summary">
          <h2>Order Summary</h2>
          {cartItems.map((item) => (
            <div key={item.id} className="order-item">
              <div className="order-item-details">
                <ProductImage product={item} alt={item.name} />
                <span>{item.name}</span>
              </div>
              <span className="order-item-price">
                UGX {item.price} x {item.quantity} = UGX {item.price * item.quantity}
              </span>
            </div>
          ))}
          <div className="order-total">
            <span>Total:</span>
            <span>UGX {totalPrice}</span>
          </div>

          <FlutterWaveButton {...flutterwaveConfig} >
            Place order
            </FlutterWaveButton>

        </div>
        {modalUrl && (
          <Modal isOpen={!!modalUrl} onClose={() => setModalUrl(null)}>
            <iframe src={modalUrl} title="Payment Modal" width="100%" height="500px"></iframe>
          </Modal>
        )}
      </div>
    </>
  );
};

export default Checkout;
